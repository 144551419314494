import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get("user", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`user/${id}`)
          .then((response) => {
            if (response.data.status == "passive") {
              response.data.status = "passive";
            } else {
              response.data.status = "active";
            }
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    userCheck(ctx, { email }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`userCheck/${email}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    permissionsAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get("permissions")
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("user", userData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveCompany(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("saveCompany", userData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    sendNewPassword(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("sendNewPassword", userData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`user/${userData.id}`, userData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
    updatePassword(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`user/updatePassword/${userData.id}`, userData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    changeStatus(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("changeStatus", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCompany(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("getCompany", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    fetchPermissionsData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("permissions", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("roles", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    saveProjectTask(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("saveProjectTask", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectTask(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("updateProjectTask", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateTimeOff(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("updateTimeOff", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    

    updateTaskDueDate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("updateTaskDueDate", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskAssigneUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("updateTaskAssigneUser", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskPriority(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("updateTaskPriority", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskIsCompleted(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("updateTaskIsCompleted", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchUsersProject(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get("projectUserList", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    userReport(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("reports/user-report", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getAllDepartments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get("getAllDepartments", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getAllSites(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get("getAllSites", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    employeeAvailability(ctx, tempData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`employeeAvailability/${tempData.userId}`, tempData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    duplicateUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("duplicateUser", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
  },
};
